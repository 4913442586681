import React from 'react'

import SEO from '../components/seo'
import StatusCode from '../components/StatusCode'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <StatusCode code="404" />
  </>
)

export default NotFoundPage
